import {EnumAccountRoles} from './Enums/EnumAccountRoles';

export class AccountRole {
    id: string;
    accountRole: EnumAccountRoles;
    role: string;
}



