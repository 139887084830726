import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OfficeSloMapping } from "../../models/officeSloMapping";
import { BaseCrudHttpService } from "./base-crud-http.service";

@Injectable()
export class OfficeSloMappingHttpService extends BaseCrudHttpService<OfficeSloMapping> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/sloOfficeAssociation/');
    }
}