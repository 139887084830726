import {Button} from './button';

export class DialogMessage {
    title: string;
    message: string;
    message2: string;
    message3: string;
    type: string; // SUCCESS, ERROR
    id: string;
    buttons: Array<Button> = [];
}
