import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {BaseCrudHttpService} from './base-crud-http.service';
import {Report} from '../../models/Report';
import {Observable} from 'rxjs';
import { Config } from 'protractor';


@Injectable()
export class ReportHttpService extends BaseCrudHttpService<Report> {
    private readonly URLgetReportSlo = this.URLMicroService + "generate/";

    constructor(httpClient: HttpClient) {
        super(httpClient, '/report/');
    }

    public exportSloReport(reportId:number, year:number, customerSupplierId:number): string{
        return this.URLgetReportSlo + reportId + "?year=" + year + "&customerSupplierId=" + customerSupplierId;
    }

    public exportSlaReport(reportId:number, slaId:number): string{
        return this.URLgetReportSlo + reportId + "?slaId=" + slaId;
    }

    public exportCalcoloSloReport(): string{
        return this.URLgetReportSlo + "calcoloSlo";
    }
    
}
