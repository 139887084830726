import {Injectable} from '@angular/core';

@Injectable()
export class KeyValueService {

    constructor() {}

    setKeyValueLists(lists: Array<any>) {
        localStorage.setItem('KEY_VALUE_LISTS', JSON.stringify(lists) );
    }

    getKeyValueLists(): Array<any> | any {
        return JSON.parse(localStorage.getItem('KEY_VALUE_LISTS'));
    }

    getKeyValueList (code: string) {
        return this.getKeyValueLists()[code];
    }

    setKeyValueList(code: string, list: Array<any>) {
        let keyValueList = this.getKeyValueLists();
        keyValueList[code] = list;
        localStorage.setItem('KEY_VALUE_LISTS', JSON.stringify(keyValueList) );
    }

    getAccountGroupByCode(code: string) {
        return this.getKeyValueList('gruppi').find(group => group.code === code);
    }

}
