import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DialogMessage} from '../../models';
import {UserLoginService} from '../../services';

@Component({
    selector: 'app-success-dialog',
    templateUrl: 'message-dialog.component.html'
})
export class MessageDialogComponent {
    srcImg = '';

    constructor(
        private userLoginService: UserLoginService,
        public dialogRef: MatDialogRef<MessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogMessage) {
        if (this.userLoginService.getTheme() !== 'dark-theme') {
            this.srcImg = this.data.type === 'SUCCESS' ? 'assets/images/success_dialog.png' : (data.type === 'ERROR' ? 'assets/images/error_dialog.png' : '');
        } else {
            this.srcImg = this.data.type === 'SUCCESS' ? 'assets/images/success_dialog_dark.png' : (data.type === 'ERROR' ? 'assets/images/error_dialog_dark.png' : '');
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
