import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {BaseService} from './base.service';
import {Pagination} from '../../models';
import { Sort } from '../../models/sort';


export class BaseCrudHttpService<T> extends BaseService {
    private partialUrl;
    protected URLMicroService;

    private URLFindAll;
    private URLFindAllWithPages;
    private URLFindAllWithPagesAndFilters;
    private URLFindById;
    private URLInsert;
    private URLUpdate;
    private URLDelete;
    private URLSearchAllWithPagesAndFilters;

    constructor(protected httpClient: HttpClient, partialUrl: string) {
        super();
        this.partialUrl = partialUrl;
        this.URLMicroService = this.baseUrl + this.partialUrl;
        this.setUrls();
    }

    public findAll(): Observable<Array<T>> {
        return this.httpClient.get<any>(this.URLFindAll);
    }

    public findAllWithPages(page: number, size: number, sortList?: Array<Sort>): Observable<Pagination<T>> {
        let params = new HttpParams();

        params = params.append('page', page.toString());
        params = params.append('size', size.toString());

        if (sortList) {
            sortList.forEach(sort => {
                params = params.append('order', sort.paramName + '_' + sort.direction.toUpperCase());
            })
        }

        return this.httpClient.get<Pagination<T>>(this.URLFindAllWithPages, {params: params});
    }

    public findAllWithPagesAndFilters(filters: string, page: number, size: number, sortList?: Array<Sort>, extras?: string): Observable<Pagination<T>> {
        let params = new HttpParams();
        if (extras != null) {
            params = params.append('Extras', extras) || params;
        }
        if (filters != null) {
            params = params.append('Filters', filters) || params;
        }

        params = params.append('page', page.toString());
        params = params.append('size', size.toString());

        if (sortList) {
            sortList.forEach(sort => {
                params = params.append('order', sort.paramName + '_' + sort.direction.toUpperCase());
            })
        }

        return this.httpClient.get<Pagination<T>>(this.URLFindAllWithPagesAndFilters, {params: params});
    }

    public searchAllWithPagesAndFilters(filters: string, page: number, size: number, sortList?: Array<Sort>): Observable<Pagination<T>> {
        let params = new HttpParams();

        params = params.append('filters', filters) || params;
        params = params.append('page', page.toString());
        params = params.append('size', size.toString());
        if (sortList) {
            sortList.forEach(sort => {
                params = params.append('order', sort.paramName + '_' + sort.direction.toUpperCase());
            })
        }

        return this.httpClient.get<Pagination<T>>(this.URLSearchAllWithPagesAndFilters, {params: params});
    }

    public findById(id: string): Observable<T> {
        return this.httpClient.get<T>(`${this.URLFindById}/${id}`);
    }

    public insert(object: T): Observable<T> {
        return this.httpClient.post<T>(this.URLInsert, object);
    }

    public update(object: T): Observable<T> {
        return this.httpClient.put<T>(this.URLUpdate, object);
	}

    public delete(id: string): Observable<T> {
        return this.httpClient.delete<T>(`${this.URLDelete}/${id}`);
    }

    setUrls() {
        this.URLFindAll = this.URLMicroService + 'findAll';
        this.URLFindAllWithPages = this.URLMicroService + 'findAllWithPages';
        this.URLFindAllWithPagesAndFilters = this.URLMicroService + 'findAllWithPagesAndFilters';
        this.URLFindById = this.URLMicroService + 'findById';
        this.URLDelete = this.URLMicroService + 'deleteById';
        this.URLInsert = this.URLMicroService + 'insert';
        this.URLUpdate = this.URLMicroService + 'update';
        this.URLSearchAllWithPagesAndFilters = this.URLMicroService + 'searchAllWithPagesAndFilters';
    }

}
