import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {BaseService} from './base.service';
import {Account} from '../../models';

@Injectable()
export class AuthHttpService extends BaseService {

    private readonly URLCheckUserLogin = this.baseLoginUrl + '/authenticate';

    constructor(private httpClient: HttpClient) {
        super();
    }

    public checkUserLogin(username: string, password: string): Observable<Account> {
        const formData: FormData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        return this.httpClient.post<Account>(this.URLCheckUserLogin, formData);
    }

}
