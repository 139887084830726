import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BaseCrudHttpService} from './base-crud-http.service';
import {Notification, NOTIFICATION_TYPE} from '../../models/notification';
import {Observable} from 'rxjs/Observable';
import {Account} from '../../models';

@Injectable()
export class NotificationsHttpService extends BaseCrudHttpService<Notification> {

    private readonly URLBadgeNumberByAccount = this.URLMicroService + 'badgeNumberByAccount';
    private readonly URLBadgeNumberByAccountGroup = this.URLMicroService + 'badgeNumberByAccountGroup';

    constructor(httpClient: HttpClient) {
        super(httpClient, '/process/notifications/');
    }

     public badgeNumberByAccount(accountId: string): Observable<number> {
         let params = new HttpParams();

         params = params.append('accountId', accountId);
         return this.httpClient.get<number>(this.URLBadgeNumberByAccount, {params: params});
     }

    public badgeNumberByAccountGroup(accountGroupCode: string): Observable<number> {
        let params = new HttpParams();

        params = params.append('accountGroupCode', accountGroupCode);
        return this.httpClient.get<number>(this.URLBadgeNumberByAccountGroup, {params: params});
    }

    generateNotification_UPLOAD_DOCUMENT_ON_GROUP(folderId: string, folderName: string, userGroupSelectedCode: string, loggedBusinessAccount: Account): Notification {
        let notification = new Notification();
        notification.type = NOTIFICATION_TYPE.UPLOAD_DOCUMENT_ON_GROUP;
        notification.description = ' ha aggiunto un documento alla cartella ';
        notification.fromBusinessAccountName = loggedBusinessAccount.name + ' ' + loggedBusinessAccount.surname;
        notification.fromBusinessAccountId = loggedBusinessAccount.id;
        notification.businessDocumentData[0].id = folderId;
        notification.businessDocumentData[0].name = folderName;
        notification.date = new Date().toISOString();
        notification.toBusinessAccountGroupCode = userGroupSelectedCode;
        return notification;
    }

    generateNotification_ASSIGNED_TASK(fromBusinessAccount: Account, toBusinessAccount: Account, taskName: string, processName: string, processId: string): Notification {
        let notification = new Notification();
        delete notification.businessDocumentData;
        notification.type = NOTIFICATION_TYPE.ASSIGNED_TASK;
        notification.fromBusinessAccountName = fromBusinessAccount.name + ' ' + fromBusinessAccount.surname;
        notification.fromBusinessAccountId = fromBusinessAccount.id;

        notification.toBusinessAccountName = toBusinessAccount.name + ' ' + toBusinessAccount.surname;
        notification.toBusinessAccountId = toBusinessAccount.id;

        notification.businessProcessName = processName;
        notification.businessProcessId = processId;

        notification.businessProcessTaskDescription = taskName;
        notification.date = new Date().toISOString();

        return notification;
    }

}
