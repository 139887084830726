import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserLoginService} from '../services';
import {environment} from 'environments/environment';

@Injectable()
export class LoginGuard implements CanActivate {

    constructor(private router: Router, private userLoginService: UserLoginService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.userLoginService.getIsLoggedin()) {
            this.router.navigate([environment.baseAppPath + '/home']);
            return false;
        }
        return true;
    }

}
