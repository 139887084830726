import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BusinessPostit} from '../../models';
import {BaseCrudHttpService} from './base-crud-http.service';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class BusinessPostitHttpService extends BaseCrudHttpService<BusinessPostit> {

    private readonly URLFindAllWithDatesFilters = this.URLMicroService + 'findAllWithDatesFilters';

    constructor(httpClient: HttpClient) {
        super(httpClient, '/postit/businessPostit/');
    }

    public findAllWithDatesFilters(dateStart: string, dateEnd: string, businessAccountId: string): Observable<Array<BusinessPostit>> {
        let params = new HttpParams();

        params = params.append('dateStart', dateStart);
        params = params.append('dateEnd', dateEnd);
        params = params.append('businessAccountId', businessAccountId);

        return this.httpClient.get<Array<BusinessPostit>>(this.URLFindAllWithDatesFilters, {params: params});
    }

}
