import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {ValidationMessageComponent, ValidationMessagesComponent} from './validation-messages.component';
import {CheckEqualValidatorDirective, ForbiddenValidatorDirective, NumberToLongDirective} from './custom-validators.component';
import {MaterialModule} from '../../../material-module';

@NgModule({
    imports: [CommonModule, RouterModule, MaterialModule],
    declarations: [
      ValidationMessagesComponent,
      ValidationMessageComponent,
      ForbiddenValidatorDirective,
      CheckEqualValidatorDirective,
      NumberToLongDirective
    ],
    exports: [
      ValidationMessagesComponent,
      ValidationMessageComponent,
      ForbiddenValidatorDirective,
      CheckEqualValidatorDirective,
      NumberToLongDirective
    ]
})
export class ValidationMessageModule {
}
