import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {KeyValue} from '../../models';
import {BaseCrudHttpService} from './base-crud-http.service';

@Injectable()
export class ResourceHttpService extends BaseCrudHttpService<KeyValue> {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/endpoint/resourceService/');
    }

}
