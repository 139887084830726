export class Shared {
    id: string;
    sharedByBusinessAccountId: string;
    sharedByBusinessAccountName: string;
    sharedByBusinessAccountSurname: string;
    sharedDate: string;
    sharedWithBusinessAccountId: string;
    sharedWithBusinessAccountName: string;
    sharedWithBusinessAccountSurname: string;
    sharingNote: string;
}