import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {RoleEndpoint} from '../../models';
import {BaseCrudHttpService} from './base-crud-http.service';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class RoleEndpointHttpService extends BaseCrudHttpService<RoleEndpoint> {

    private readonly URLFindAllByRoleToEnable = this.URLMicroService + 'findAllByRoleToEnable';
    private readonly URLFindAllByRole = this.URLMicroService + 'findAllByRole';

    constructor(httpClient: HttpClient) {
        super(httpClient, '/endpoint/roleEndpointService/');
    }

    public findAllByRoleToEnable(roleId: string): Observable<Array<RoleEndpoint>> {
        let params = new HttpParams();

        params = params.append('roleId', roleId);
        return this.httpClient.get<Array<RoleEndpoint>>(this.URLFindAllByRoleToEnable, {params: params});
    }

    public findAllByRole(roleId: string): Observable<Array<RoleEndpoint>> {
        let params = new HttpParams();

        params = params.append('roleId', roleId);
        return this.httpClient.get<Array<RoleEndpoint>>(this.URLFindAllByRole, {params: params});
    }

}