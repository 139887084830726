import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ImportRecordFieldLog } from "../../../models/Importer/ImportRecordFieldLog";
import { ImportRecordLog } from "../../../models/Importer/ImportRecordLog";
import { BaseCrudHttpService } from "../base-crud-http.service";


@Injectable()
export class ImportRecordFieldLogHttpService extends BaseCrudHttpService<ImportRecordFieldLog> {
    private _findByRecordId = this.URLMicroService + 'findByRecordId';

    constructor(httpClient: HttpClient) {
        super(httpClient, '/importRecordFieldLog/');
    }

    public findByRecordId(idRecord: string): Observable<ImportRecordFieldLog[]> {
        return this.httpClient.get<ImportRecordFieldLog[]>(this._findByRecordId + '/' + idRecord );

	}
}