import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {UserLoginService} from '../services';
import {CustomSnackBarComponent} from '../modules';
import {MatSnackBar} from '@angular/material';

@Injectable()
export class PermissionsGuard implements CanActivate, CanActivateChild  {
    resourceIds: string[];

    constructor(private router: Router, private userLoginService: UserLoginService, private snackBar: MatSnackBar) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivateFnc(route);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivateFnc(route);
    }

    canActivateFnc(route: ActivatedRouteSnapshot) {
        this.resourceIds = route.data['resourceIds'] as string[];

        if (this.checkPermission()) {
            return true;
        }

        this.snackBar.openFromComponent(CustomSnackBarComponent, {
            data: 'response.permission_denied',
            panelClass: 'error-snack-bar'
        });
        return false;
    }

    checkPermission() {
        if (this.resourceIds && this.resourceIds.length) {
            return this.userLoginService.getPermissions().some(permission => {
                return this.resourceIds.some(resourceId => {
                    if (permission === resourceId) {
                        return true;
                    }
                });
            });
        } else {
            return true;
        }
    }

}
