import {AfterViewInit, Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {MatAutocomplete, MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';
import {Account, BaseMatTableColumn, DialogMessage, Paginator, Slo, SectionSLO, AccountRole} from '../../models';
import {UserLoginService,SectionSloHttpService,SloHttpService, ReportHttpService} from '../../services';
import {NgxPermissionsService} from 'ngx-permissions';
import {CustomSnackBarComponent} from '../custom-snack-bar/custom-snack-bar.component';
import {MessageDialogComponent} from '../message-dialog/message-dialog.component';
import {SchematicsAngularComponent} from '@angular/cli/lib/config/schema';
import {ActivatedRoute, Router} from '@angular/router';
import {EnumMonitoringSLO} from './../../models/Enums/EnumMonitoringSLO';
import {EnumTargetSLOType} from './../../models/Enums/EnumTargetSLOType';
import {EnumSectionType} from './../../models/Enums/EnumSectionType';
import {DatePipe} from '@angular/common';

import { element } from '@angular/core/src/render3';
import { EnumOperator } from '../../models/Enums/EnumOperator';
import {SloValidation} from '../../services/slo-validation';
import { Sort } from '../../models/sort';


@Component({
    selector: 'app-base-material-table',
    templateUrl: './base-material-table.component.html',
    styleUrls: ['./base-material-table.component.scss']
})
export class BaseMaterialTableComponent implements OnInit, AfterViewInit {

	@Input() statusSloFieldsEnabled: boolean[];
	@Input() reasonFieldsEnabled: boolean[];

	@Input() dataSourceService;
    @Input() columns: BaseMatTableColumn;
    @Input() filters: string;
    @Input() extras: string;
    @Input() direction: string;
    @Input() modalDetailComponent: any;
    @Input() hidePaginator?: boolean;
    @Input() hideAction ? = false;
    @Input() actionButtonsExtended ? = true;

	@Input() detailActionNotActive = false;
	@Input() sectionSloStatusAction = false;
    @Input() updateActionNotActive = false;
    @Input() deleteActionNotActive = false;
    @Input() downloadActionNotActive = false;
    @Input() forceSort ? = [new Sort('createdAt','ASC')];

    @ViewChild('matAutocomplete') matAutocomplete: MatAutocomplete;
    @ViewChild(MatPaginator) matPaginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

	_service: any;
    dataSource: Array<Account> = [];
    paginator: Paginator = new Paginator();
    filter = '';
    isSpinnerActive = false;
	displayedColumns: string[] = [];
	sectionSlo: SectionSLO = new SectionSLO();
	slo: Slo = new Slo();
	sloList: Slo[] = [];
	sectionList: SectionSLO[] = [];
	enumTargetSLOType = EnumTargetSLOType;
	enumMonitoringSLO = EnumMonitoringSLO;
	enumSectionType = EnumSectionType;
	isOnchange = false;
	isSuperAdmin = false;
	isOnlyAgent = false;
	notExistSectionSloMonth =false;

	selectedMonthIndex: string;
	monthNames: any;
    allMonthField = false;

    loggedUser: Account;

    constructor(
		private datePipe: DatePipe,
        private _injector: Injector,
        private _ngxPermissionsService: NgxPermissionsService,
        private _userLoginService: UserLoginService,
        public _matDialog: MatDialog,
		public _snackBar: MatSnackBar,
		private _router: Router,
		private _sectionSloHttpService: SectionSloHttpService,
		private _sloHttpService: SloHttpService,
		private _reportHttpService: ReportHttpService
    ) {
		this.statusSloFieldsEnabled = [];
		this.reasonFieldsEnabled = [];
		this.loggedUser = _userLoginService.getUserObjectField('BUSINESS_ACCOUNT');
	}

    ngOnInit() {
		this._service = this._injector.get<any>(this.dataSourceService);
        this._ngxPermissionsService.loadPermissions(this._userLoginService.getPermissions());
		this.isSuperAdmin = this.getUserRole(this.loggedUser.roles);
		this.isOnlyAgent = this.getOnlyAgentUserRole(this.loggedUser.roles);
        this.columns = this.columns || new BaseMatTableColumn();
        this.displayedColumns = Object.assign([], this.columns.displayedColumns);
		this.paginator.sortList = this.forceSort;

        if (!this.hideAction) {
            this.displayedColumns.push('azioni');
		}

		this.monthNames =[
			{id: 1, value: 'January', disabled:false},
			{id: 2, value: 'February', disabled:false},
			{id: 3, value: 'March', disabled:false},
			{id: 4,value: 'April', disabled:false},
			{id: 5,value: 'May', disabled:false},
			{id: 6,value: 'June', disabled:false},
			{id: 7,value: 'July', disabled:false},
			{id: 8,value: 'August', disabled:false},
			{id: 9,value: 'September', disabled:false},
			{id: 10,value: 'October', disabled:false},
			{id: 11,value: 'November', disabled:false},
			{id: 12,value: 'December', disabled:false}
		  ];

		this.checkMonthField(this.isSuperAdmin);
		this.loadDataSource();

    }

    ngAfterViewInit(): void {
        this.sort.sortChange.subscribe((pag) => this.loadDataSourceSort(pag));
    }

    onPageChanged() {
        this.paginator.pageSize = this.matPaginator.pageSize;
        this.paginator.page = this.matPaginator.pageIndex;
        this.loadDataSource();
    }

    loadDataSourceSort(sort) {
        // this.paginator.sort = sort.active;
        // this.paginator.direction = sort.direction;
        this.loadDataSource(true);
    }

    loadDataSource(resetPaginator?: boolean) {
        if (resetPaginator) {
            this.paginator.page = 0;
            this.matPaginator.pageIndex = 0;
        }

        let spinnerTimeout = setTimeout(() => {
            this.isSpinnerActive = true;
        }, 1000);

        if (this.filters || this.extras) {
            this.findAllWithPagesAndFilters(spinnerTimeout);
        } else {
            this.findAllWithPages(spinnerTimeout);
        }

    }

    findAllWithPagesAndFilters(spinnerTimeout: any) {
        this._service.findAllWithPagesAndFilters(this.filters, this.paginator.page, this.paginator.pageSize, this.paginator.sortList, this.extras).subscribe((response) => {
            clearTimeout(spinnerTimeout);
            this.isSpinnerActive = false;
            this.paginator.length = response.totalElements;
			this.dataSource = response.content;

			this.dataSource.forEach(element => {
				this.statusSloFieldsEnabled[element.id] = true;
				this.sloList=  response.content;
			});

			this.dataSource.forEach(element => {
				this.reasonFieldsEnabled[element.id] = true;
			});

		});
	}

    findAllWithPages(spinnerTimeout: any) {
        this._service.findAllWithPages(this.paginator.page, this.paginator.pageSize, this.paginator.sortList).subscribe((response) => {
            clearTimeout(spinnerTimeout);
            this.isSpinnerActive = false;
            this.paginator.length = response.totalElements;
			this.dataSource = response.content;
			this.slo = response.content;

			this.dataSource.forEach(element => {
				this.statusSloFieldsEnabled[element.id] = true;
			});
			this.dataSource.forEach(element => {
				this.reasonFieldsEnabled[element.id] = true;
			});

        });
	}



    openModal(id: string) {
        const dialogConfig = new DialogMessage();
        dialogConfig.title = 'login.dialog_title';
        dialogConfig.message = 'dialog_msgs.delete';

        dialogConfig.buttons = [
            {name: 'buttons.yes', color: '', action: 'DELETE', icon: ''},
            {name: 'buttons.cancel', color: '', action: 'CANCEL', icon: ''}
        ];

        const dialogRef = this._matDialog.open(MessageDialogComponent, {
            width: '375px',
            data: dialogConfig
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === 'DELETE') {
                this.delete(id);
            }
        });
    }

    delete(id: string) {
        this._service.delete(id).subscribe(() => {
            this._snackBar.openFromComponent(CustomSnackBarComponent, {
                data: 'response.DELETED',
                panelClass: 'success-snack-bar'
            });
            this.loadDataSource(true);
        });
    }

    openDetailModal(obj: any) {
        const dialogConfig = new DialogMessage();
        dialogConfig.id = obj.id;
        const dialogRef = this._matDialog.open(this.modalDetailComponent, {
            width: '375px',
            minWidth: '375px',
            data: dialogConfig
        });
	}


    openReportSLA(obj: any){
    	 window.open(this._reportHttpService.exportSlaReport(7,obj.id), '_blank'); 
	}

    getUserRole(userRoles : AccountRole[]){
        for (let userRole of userRoles) {
          if(userRole.role === 'SUPERADMIN'){
                return true;
                break;
          }
         }
	}

	getOnlyAgentUserRole(userRoles : AccountRole[]){
        for (let userRole of userRoles) {
          if(userRoles.length===1 && userRole.role === 'AGENT'){
                return true;
                break;
          }
         }
	}

/* 	need field changes and then have to update in db and change front-end button */

	updateStatusSlo(sectionSloId: number, statusSLO: string, reason: string,  id: number, targetSloType : EnumTargetSLOType , cutoffDate: Date ,operator: EnumOperator) {

		if(this.validateNumber(String(statusSLO), targetSloType)){

		// case of update - section slo != null
		 if (sectionSloId) {
			this._sectionSloHttpService.findBySectionId(String(sectionSloId)).subscribe((sections) => {

				//sections.prevSLOStatus  = this.sectionSlo.slostatus;

				this.sectionSlo = sections;
				//this.isSuperAdmin = this.getUserRole(this.sectionSlo.slo.admin.roles);

		if(this.isReasonRequired(reason, targetSloType, String(statusSLO), sections.slo.targetSLO,operator) && this.checkCutoffDate(cutoffDate, this.isSuperAdmin)){



				this.sectionSlo.prevSLOStatus = sections.slostatus;
				this.sectionSlo.prevMotivation = sections.motivation;
				this.sectionSlo.motivation = reason;
				this.sectionSlo.hasValue = "NOT_SET";

				if(targetSloType === 'TEXTUAL'){
				this.sectionSlo.slostatusText = statusSLO;
				}else{
					this.sectionSlo.slostatus = parseFloat(statusSLO);
				}

				if (this.isOnchange) {
					this.sectionSlo.sectionSloSelectedMonth = this.selectedMonthIndex;
				} else {
					this.sectionSlo.sectionSloSelectedMonth = String(new Date(sections.updatedAt).getMonth()+1);
				}



				this._sectionSloHttpService.update(this.sectionSlo).subscribe(response => {

				//if(this.sectionSlo.slo.targetSLO < parseFloat(statusSLO)){
					//this.updateReason(sectionSloId,"",id);
					//}else{
							this._snackBar.openFromComponent(CustomSnackBarComponent, {
						data: 'response.SAVE',
						panelClass: 'success-snack-bar'
						});
					//}
					this.loadDataSource();
					// this.navigateToSloList();
				});

			}

			});
		} else { // insert case



			this._sloHttpService.findBySloId(String(id)).subscribe((sections) => {
				this.slo = sections;
				this.sectionSlo.slo = this.slo;
				this.sectionSlo.id = this.slo.sectionSloId;
				//this.isSuperAdmin = this.getUserRole(this.sectionSlo.slo.admin.roles);

		if(this.isReasonRequired(reason, targetSloType, String(statusSLO), this.slo.targetSLO, operator) && this.checkCutoffDate(cutoffDate, this.isSuperAdmin)){

				if(targetSloType === 'TEXTUAL'){
					this.sectionSlo.slostatusText = statusSLO;
					}else{
						this.sectionSlo.slostatus = parseFloat(statusSLO);
					}

				this.sectionSlo.sectionType = this.slo.sectionType;
				this.sectionSlo.motivation = reason;
				this.sectionSlo.hasValue = 'NOT_SET';

			if (this.isOnchange) {
				this.sectionSlo.sectionSloSelectedMonth = this.selectedMonthIndex;
			}


				this._sectionSloHttpService.insert(this.sectionSlo).subscribe(response => {
					this._snackBar.openFromComponent(CustomSnackBarComponent, {
						data: 'response.SAVE',
						panelClass: 'success-snack-bar'
					});

					this.loadDataSource();
			  //this.navigateToSloList();
			   });
		} // end isReasonRequired
			});


		} // end insert service
		}

	}


// updateReason(sectionSloId: number,  reason: string, id: number) {



// 	if (sectionSloId) {
// 				this._sectionSloHttpService.findBySectionId(String(sectionSloId)).subscribe((sections) => {
// 				this.sectionSlo = sections;

// 				if(this.validateReason(reason, sections.motivation,sections.slostatus, sections.slo.targetSLO)){

// 		this.sectionSlo.motivation = sections.motivation;
// 		this.sectionSlo.motivation = reason;
// 		this.sectionSlo.slostatus =	sections.slostatus;


// 		this._sectionSloHttpService.update(this.sectionSlo).subscribe(response => {
//                 this._snackBar.openFromComponent(CustomSnackBarComponent, {
//                     data: 'response.SAVE',
//                     panelClass: 'success-snack-bar'
// 				});
// 				this.loadDataSource();
// 				// this.navigateToSloList();

//             });
// 			}
// 		});
// 		} else {

// 			this._sloHttpService.findBySloId(String(id)).subscribe((sections) => {
// 				this.slo = sections;
// 				this.sectionSlo.slo = this.slo;
// 				this.sectionSlo.id = this.slo.sectionSloId;
// 				this.sectionSlo.slostatus = this.slo.SLOStatus;
// 				this.sectionSlo.sectionType = this.slo.sectionType;
// 				this.sectionSlo.motivation = reason;

// 			this._sectionSloHttpService.insert(this.sectionSlo).subscribe(response => {
// 				this._snackBar.openFromComponent(CustomSnackBarComponent, {
// 					data: 'response.SAVE',
// 					panelClass: 'success-snack-bar'
// 				});

// 				this.loadDataSource();
// 				//this.navigateToSloList();

// 			});
// 	});
// 		}
// 	}

	  navigateToSloList() {
		 /*   setTimeout(() => {
   				 window.location.reload();
 	 		}, 50);  */
		this._router.navigate(['/app/gestione-slo/lista']);

	}


   isReasonRequired(reason:string, targetSloType: EnumTargetSLOType, statusSLO: string, targetSlo : number, operator:EnumOperator) {
	   var regChar = /^.{2,}$/;
        if (targetSloType === EnumTargetSLOType.NUMERIC || targetSloType === EnumTargetSLOType.PERCENT) {
                    if (SloValidation.isNonInTargetSLO(parseFloat(statusSLO),targetSlo, operator)) {
                    	if(reason == null){
                    		 this._snackBar.openFromComponent(CustomSnackBarComponent, {
                    	             					data: 'response.INVALIDREASON',
                    	             					panelClass: 'error-snack-bar'
                    	             				});
                    	             			 	this.loadDataSource();
                    	             				return false;
                    		}else {
                    		var out = reason.replace(/\s/g, "");
                    			if(out === "" || !regChar.test(reason)){
                    	                    		 this._snackBar.openFromComponent(CustomSnackBarComponent, {
                    	             					data: 'response.INVALIDREASON',
                    	             					panelClass: 'error-snack-bar'
                    	             				});
                    	             			 	this.loadDataSource();
                    	             				return false;
                    	                    	}else{
                    	                    		return true;
                    	                    	}
                    		}

                    }else{
                  	  return true;
                    }
        } else{
        	return true;
        }
	}

	 validateNumber(statusSLO : string, targetSloType: EnumTargetSLOType) {

		 if (targetSloType !== EnumTargetSLOType.TEXTUAL) {
		  var regNum = /^[0-9]*$/;

		  var regPerc =/^\d{0,3}(\.\d{0,2}){0,9}(\,\d{0,2}){0,9}$/;

		  //Target Type == PERCENT (it takes 0 - 100)
		  if(targetSloType == EnumTargetSLOType.PERCENT){
			  if (!regPerc.test(statusSLO) || parseFloat(statusSLO) > 100.0 ) {
				  this._snackBar.openFromComponent(CustomSnackBarComponent, {
						data: 'response.INVALID',
						panelClass: 'error-snack-bar'
					});
					this.loadDataSource();
					return false;
			  }else {
				  return true;
			  }
				}
		  //Target Type == NUMERIC (it takes 0 - infinity)
		  else if(targetSloType == EnumTargetSLOType.NUMERIC){
			  if (!regNum.test(statusSLO)) {
				  this._snackBar.openFromComponent(CustomSnackBarComponent, {
						data: 'response.INVALID',
						panelClass: 'error-snack-bar'
					});
					this.loadDataSource();
					return false;
			  }else {
				  return true;
			  }
				}
		 } else if (statusSLO === "undefined" || statusSLO === "") {
			 this._snackBar.openFromComponent(CustomSnackBarComponent, {
					data: 'response.INVALID',
					panelClass: 'error-snack-bar'
				});
			 	this.loadDataSource();
				return false;
			} else {
				return true;
			}
	}

	// validateReason(newReason: string, savedReason: string, savedSloStatus: number ,targetSlo : number){

	// 		if ((savedReason !== null || savedReason !== "") && (newReason === "" || newReason === null) && savedSloStatus !== 100) {

	// 			this._snackBar.openFromComponent(CustomSnackBarComponent, {
	// 				data: 'response.INVALID',
	// 				panelClass: 'error-snack-bar'
	// 			});
	// 			this.loadDataSource();
	// 			return false;
	// 		} else {
	// 			return true;
	// 		}

	// }

	validateMonth(selectedMonthIndex :string){
		if(selectedMonthIndex == null || selectedMonthIndex == ""){

			this._snackBar.openFromComponent(CustomSnackBarComponent, {
				data: 'response.INVALIDMONTH',
				panelClass: 'error-snack-bar'
			});
			this.loadDataSource();
			return false;
		} else {
			return true;
		}
	}

	checkCutoffDate(sloCutOffDate: Date, isSuperAdmin: boolean){
		var now = new Date();
		sloCutOffDate = new Date(sloCutOffDate);
		if((sloCutOffDate < now) && !isSuperAdmin){
			this._snackBar.openFromComponent(CustomSnackBarComponent, {
				data: 'response.INVALIDCUTOFFDATE',
				panelClass: 'error-snack-bar'
			});
			this.loadDataSource();
			return false;
		} else {
			return true;
		}
	}


	checkMonthField(isSuperAdmin: boolean){
	    	if(isSuperAdmin){
	    		this.allMonthField = true;
	    	}else{
	    		this.allMonthField = false;
	    	}

	    }

	onChange(selected:string, id:string, selItem:any) {

				this.selectedMonthIndex = selected;
				this.isOnchange = true;

				//FOR AGENT CALL SECTIONSLO SERVICES GET HISTORY
				if (this.isOnlyAgent) {
					var selectedMonth =parseInt(selected);
					selItem.motivationEditable = false;
					this.getSectionSLOCallService(id,selectedMonth,selItem);
				}

			   }

	isDisabled(month: any,sloEndDate: Date):boolean{
		var now = new Date();
		var sloEndDateMonth = new Date(sloEndDate).getMonth()+1;
		var sloEndDateYear =new Date(sloEndDate).getFullYear();
		if ((sloEndDateYear <= now.getFullYear()) && (sloEndDateMonth < (now.getMonth()+1))) {
			return true;
		} else {
			if(month < (now.getMonth()+1)){
				return true;
			   }else{
				return false;
			   }
		}

	}


	getSectionSLOCallService(id: string, selected:number, selItem: any){
		 this._sectionSloHttpService.findBySLOId(id).subscribe((sections) => {
			 if (sections.length > 0) {
                 this.sectionSlo = sections[0];
                 for (let sectionSloHistoryMonth of this.sectionSlo.sectionSLOHistoryUpdatedMonth) {
                	 if(selected !== sectionSloHistoryMonth && selected !== parseInt(this.datePipe.transform(this.sectionSlo.updatedAt, 'MM'))){
						selItem.motivationEditable = true;
                	 }else{
						selItem.motivationEditable = false;
						break;
					 }
                 }
			 }
		 });
	}
}
