import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AccountGroup} from '../../models';
import {BaseCrudHttpService} from './base-crud-http.service';

@Injectable()
export class AccountGroupHttpService extends BaseCrudHttpService<AccountGroup> {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/configurations/accountGroup/');
    }

}
