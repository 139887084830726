import { Sort } from "./sort";

export class Paginator {
    pageSize = 10;
    page = 0;
    length: number;
    filter: string;
    sortList: Array<Sort> = [];
    search: string;
}
