import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'filterOnName'
})
export class FilterOnNamePipe implements PipeTransform {
    transform(items: any[], searchText: string, fieldName: string): any[] {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }

        searchText = searchText.toLowerCase();
        return items.filter( it => {
            if (it[fieldName]) {
                return it[fieldName].toLowerCase().includes(searchText);
            }
        });
    }
}
