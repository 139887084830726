import {KeyValue} from './keyValue';

export class AddressBusinessAccount {
    address: string;
    cap: string;
    city: string;
    country: KeyValue = new KeyValue();
    province: KeyValue = new KeyValue();
    id: string;
    isDefault: boolean = false;
    name: string;
    remarks: string;
    invoicingAddress: boolean = false;
}