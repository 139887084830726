import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomSnackBarComponent} from '../../custom-snack-bar/custom-snack-bar.component';
import {MatSnackBar} from '@angular/material';

@Component({
    selector: 'app-form-upload',
    templateUrl: './form-upload.component.html',
    styleUrls: ['./form-upload.component.css']
})
export class FormUploadComponent implements OnInit {

    @Input() selectedFile: File;
    @Input() limitExtensions?: string;
    @Output() selectedFileChange = new EventEmitter();

    newFile: any;

    constructor(public snackBar: MatSnackBar) {
    }

    ngOnInit() {
    }

    selectFile(event) {
        // this.selectedFileChange.emit(event.target.files.length ? event.target.files[0] : undefined);

        if (this.limitExtensions && event.target.files.length) {
            let arrayName: String[];
            arrayName = event.target.files[0].name.split('.');
            if (arrayName[arrayName.length - 1] !== this.limitExtensions) {

                this.snackBar.openFromComponent(CustomSnackBarComponent, {
                    data: 'response.WRONG_EXTENSION',
                    panelClass: 'error-snack-bar'
                });
                this.selectedFileChange.emit(undefined);
                this.newFile = null;
            } else {
                this.selectedFileChange.emit(event.target.files[0]);
            }
        } else {
            if (event.target.files.length) {
                this.selectedFileChange.emit(event.target.files[0]);
            } else {
                this.selectedFileChange.emit(undefined);
            }
        }
    }

}
