import {Component, Input, OnInit} from '@angular/core';
import {Account} from '../../models';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-user-icon',
    templateUrl: './user-icon.component.html',
    styleUrls: ['./user-icon.component.scss']
})
export class UserIconComponent implements OnInit {

    @Input() actor: Account;
    @Input() color?: string;
    @Input() link?: boolean;

    iniziali: string;
    public env = environment;

    constructor() {
    }

    ngOnInit() {
        this.actor = this.actor || new Account();
        this.color = this.color || null;
        this.iniziali = (this.actor.name ? this.actor.name.charAt(0) : '' ) + (this.actor.surname ? this.actor.surname.charAt(0) : '');
    }

}
