import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {BaseService} from '../../services';

@Injectable()
export class UploadFileService extends BaseService {

    private readonly URLMicroService = this.baseUrl + '/document/businessDocument/';

    private readonly URLUploadFile = this.URLMicroService + 'file/upload';

    constructor(private httpClient: HttpClient) {
        super();
    }

    pushFileToStorage(file: File): Observable<any> {

        localStorage.setItem('isFileToStorage', 'true');
        const formData: FormData = new FormData();
        formData.append('file', file);

        const req = new HttpRequest('POST', this.URLUploadFile, formData, {
            reportProgress: true,
            responseType: 'json'
        });
        return this.httpClient.request(req);
    }

}
