import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ImportRecordLog } from "../../../models/Importer/ImportRecordLog";
import { BaseCrudHttpService } from "../base-crud-http.service";


@Injectable()
export class ImportRecordLogHttpService extends BaseCrudHttpService<ImportRecordLog> {
    private _findByExecutionId = this.URLMicroService + 'findByExecutionId';

    constructor(httpClient: HttpClient) {
        super(httpClient, '/importRecordLog/');
    }

    public findByExecutionId(idExecution: string): Observable<ImportRecordLog[]> {
        return this.httpClient.get<ImportRecordLog[]>(this._findByExecutionId + '/' + idExecution );

	}
}