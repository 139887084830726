import {Injectable} from '@angular/core';
import { environment } from '../../../environments/environment';
import {MenuNavItem} from '../models';

// CLIENTSUPPORT, AGENT, ADMIN, SUPERADMIN

let gestioneClienteFornitoreBasePath = '/' + environment.baseAppPath + '/gestione-cliente-fornitore';
let gestioneSlaBasePath = '/' + environment.baseAppPath + '/gestione-sla';
let gestioneSloBasePath = '/' + environment.baseAppPath + '/gestione-slo';
let gestioneUtenteBasePath = '/' + environment.baseAppPath + '/gestione-utente';
let gestioneUfficiBasePath = '/' + environment.baseAppPath + '/gestione-uffici';
let importerBasePath = '/' + environment.baseAppPath + '/importer';
let calcoloSloBasePath = '/' + environment.baseAppPath + '/calcolo-slo';

const MENUITEMSAPPBODY = [
    {
        state: gestioneClienteFornitoreBasePath, type: 'menu', name: 'menu.COMPANIES', permissions: ['ADMIN'],
        menu: [

            {state: gestioneClienteFornitoreBasePath + '/nuovo/azienda', type: 'link', name: 'menu.ADD_NEW_COMPANY'},
            {
                type: 'menu', name: 'menu.SUPPLIER_CUSTOMER_REGISTRY',
                menu: [
                    {
                        state: gestioneClienteFornitoreBasePath + '/registro/fornitori',
                        type: 'link',
                        name: 'menu.SUPPLIER_REGISTRY'
                    },
                    {
                        state: gestioneClienteFornitoreBasePath + '/registro/clienti',
                        type: 'link',
                        name: 'menu.CUSTOMER_REGISTRY'
                    }
                ]
            }
        ]
    },
    {
        state: gestioneUfficiBasePath, type: 'menu', name: 'menu.OFFICES', permissions: ['ADMIN'],
        menu: [
            {state: gestioneUfficiBasePath + '/nuovo', type: 'link', name: 'menu.ADD_NEW_OFFICE'},
            {state: gestioneUfficiBasePath + '/registro', type: 'link', name: 'menu.OFFICE_REGISTRY'}
        ]
    },
    {
        state: gestioneSlaBasePath, type: 'menu', name: 'menu.SLA', permissions: ['ADMIN'],
        menu: [
            {state: gestioneSlaBasePath + '/nuovo', type: 'link', name: 'menu.ADD_NEW_SLA'},
            {state: gestioneSlaBasePath + '/lista', type: 'link', name: 'menu.SLA_LIST'}
        ]
    },
    {
        state: gestioneSloBasePath, type: 'menu', name: 'menu.SLO', permissions: ['ADMIN', 'AGENT', 'CLIENTSUPPORT'],
        menu: [
            {state: gestioneSloBasePath + '/nuovo', type: 'link', name: 'menu.ADD_NEW_SLO', permissions: ['ADMIN']},
            {
                state: gestioneSloBasePath + '/lista',
                type: 'link',
                name: 'menu.SLO_LIST',
                permissions: ['ADMIN', 'AGENT']
            },
            {
                state: gestioneSloBasePath + '/storico',
                type: 'link',
                name: 'menu.HISTORY',
                permissions: ['CLIENTSUPPORT']
            }
        ]
    },
    {
        state: gestioneUtenteBasePath, type: 'menu', name: 'menu.SETTINGS', permissions: ['SUPERADMIN'],
        menu: [
            {state: gestioneUtenteBasePath + '/nuovo', type: 'link', name: 'menu.ADD_NEW_USER'},
            {state: gestioneUtenteBasePath + '/lista', type: 'link', name: 'menu.USER_LIST'},
            
        ]
    },
    {   state: importerBasePath, type: 'menu', name: 'menu.IMPORTER', permissions: ['ADMIN'],
        menu: [
            {state: importerBasePath + '/log-registry', type: 'link', name: 'menu.IMPORTER_LOG'}
        ]
    },
    {   state: calcoloSloBasePath, type: 'menu', name: 'menu.CALCOLO_SLO', permissions: ['SUPERADMIN'],
        menu: [
            {state: calcoloSloBasePath + '/nuovo', type: 'link', name: 'menu.CALCOLO_SLO_NEW'},
            {state: calcoloSloBasePath + '/registry', type: 'link', name: 'menu.CALCOLO_LOG'},
            {state: calcoloSloBasePath + '/association', type: 'link', name: 'menu.CALCOLO_SLO_OFFICE_MAPPING'},
            {state: calcoloSloBasePath + '/calendar', type: 'link', name: 'menu.CALCOLO_SLO_CALENDAR' },
            {type: 'exportExcel', name: 'menu.ESPORTA_REPORT_EXCEL'}
        ]
    }
];

@Injectable()
export class MenuItemsAppBody {
    getMenuitem(): MenuNavItem[] {
        return MENUITEMSAPPBODY;
    }
}
