import {Component, Input, OnInit} from '@angular/core';
import {Account} from '../../models';
import {MatDialog} from '@angular/material';
import {ActorSmartListComponent} from '../actor-smart-list/actor-smart-list.component';
import {environment} from 'environments/environment';

@Component({
  selector: 'app-user-list-icon',
  templateUrl: './user-list-icon.component.html',
  styleUrls: ['./user-list-icon.component.scss']
})
export class UserListIconComponent implements OnInit {

  @Input() usersList?: Account[] = [];
  @Input() color ? = '';
  public env = environment;

  constructor(public matDialog: MatDialog) {
  }

  ngOnInit() {

  }

  openUserListDialog() {
      let dialogOpenUserListConfig = {};
      dialogOpenUserListConfig['actors'] = this.usersList;
      dialogOpenUserListConfig['title'] = 'process.team';
      dialogOpenUserListConfig['needPagination'] = false;
      dialogOpenUserListConfig['selectableList'] = false;

      const dialogRef = this.matDialog.open(ActorSmartListComponent, {
          width: '500px',
          data: dialogOpenUserListConfig
      });

      dialogRef.afterClosed().subscribe(result => {
          if (result === 'OK') {
          }
      });
  }

}
