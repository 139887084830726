import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {BaseCrudHttpService} from './base-crud-http.service';
import {Slo} from '../../models/slo';
import {Observable} from 'rxjs';
import { Config } from 'protractor';

@Injectable()
export class SloHttpService extends BaseCrudHttpService<Slo> {
    private readonly URLfindSLOCodeForAutocomplete = this.baseUrl + '/slo/findBySloCodeForAutocomplete/';
    private _findById = this.URLMicroService + 'findById';
    private _createNewVersion = this.URLMicroService + 'createNewVersion';
    private _findBySloCodeMaxVersion = this.URLMicroService + 'findBySloCodeMaxVersion/';

    constructor(httpClient: HttpClient) {
        super(httpClient, '/slo/');
    }

    public findSLOCodeForAutocomplete(slocode: string): Observable<Slo[]> {
        return this.httpClient.get<any>(this.URLfindSLOCodeForAutocomplete + slocode);
    }

   public findBySloId(id: string): Observable<Slo> {
		return this.httpClient.get<Slo>(this._findById + '/' + id );
    }

   public createNewVersion(slo:Slo):Observable <Slo> {
       return this.httpClient.post<Slo>(this._createNewVersion,slo);
   }

   public findBySloCodeMaxVersion(slocode: string): Observable<number> {
       return this.httpClient.get<number>(this._findBySloCodeMaxVersion + slocode);
   }

}
