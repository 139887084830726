import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base-crud-http.service';
import {Account} from '../../models/account';

@Injectable()
export class UserHttpService extends BaseCrudHttpService<Account> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/account/');
    }
}
