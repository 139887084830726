import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-head-title-page',
    templateUrl: './head-title-page.component.html',
    styleUrls: ['./head-title-page.component.scss']
})
export class HeadTitlePageComponent implements OnInit {

    @Input() title: string;
    @Input() color?: string;


    constructor() {
    }

    ngOnInit() {
    }

}
