import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WorkingDay } from "../../models/workingDay";
import { BaseCrudHttpService } from "./base-crud-http.service";

@Injectable()
export class WorkingDaysHttpService extends BaseCrudHttpService<WorkingDay> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/calendar/');
    }
}