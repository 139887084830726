import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {KeyValue} from '../../models';
import {BaseCrudHttpService} from './base-crud-http.service';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class ProvinceHttpService extends BaseCrudHttpService<KeyValue> {

    private readonly URLFindForAutoComplete = this.URLMicroService + 'findForAutoComplete';

    constructor(httpClient: HttpClient) {
        super(httpClient, '/configurations/province/');
    }

    public searchProvinceAutoComplete(text: string): Observable<Array<KeyValue>> {
        let params = new HttpParams();

        params = params.append('filter', text);
        return this.httpClient.get<any>(this.URLFindForAutoComplete, {params: params});
    }

}
