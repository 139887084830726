import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Account, BusinessAccountLite} from '../../models';
import {BaseCrudHttpService} from './base-crud-http.service';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class AccountHttpService extends BaseCrudHttpService<Account> {

    private readonly URLFindByUsername = this.URLMicroService + 'findByUsername';
    private readonly URLFindForAutoComplete = this.URLMicroService + 'findForAutoComplete';
    private readonly URLFindUserAccountForAutoComplete = this.URLMicroService + 'findUserAccountForAutoComplete';

    constructor(httpClient: HttpClient) {
        super(httpClient, '/account/businessAccount/');
    }

    public findByUsername(username: string): Observable<Account> {
        return this.httpClient.get<any>(this.URLFindByUsername + '/' + username);
    }

    public searchCompanyAutoComplete(text: string): Observable<Array<BusinessAccountLite>> {
        let params = new HttpParams();

        params = params.append('filter', text);
        return this.httpClient.get<any>(this.URLFindForAutoComplete, {params: params});
    }

    public searchUserAutoComplete(text: string): Observable<Array<Account>> {
        let params = new HttpParams();

        params = params.append('filter', text);
        return this.httpClient.get<Array<Account>>(this.URLFindUserAccountForAutoComplete, {params: params});
    }

}
