import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {BaseService} from './base.service';

@Injectable()
export class KeyValueHttpService extends BaseService {
    private readonly URLkeyValueLists = this.baseUrl + '/configurations/configurations/getAllConfigurations';

    constructor(private httpClient: HttpClient) {
        super();
    }

    public getKeyValueLists(): Observable<any> {
        return this.httpClient.get<any>(this.URLkeyValueLists);
    }
}
