import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseService} from '../../services';



@Injectable()
export class CalcoloSloHttpService extends BaseService {

    private URLUploadFile = this.baseUrl + '/calcoloSlo/upload';

    constructor(private httpClient: HttpClient) {
        super();
    }

    upload(file: File) {

      localStorage.setItem('isFileToStorage', 'true');
      const formData: FormData = new FormData();
      formData.append('file', file);

      return this.httpClient.post(this.URLUploadFile,formData, {observe: 'response', responseType: 'blob'});
  }

}