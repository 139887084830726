import {Directive, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';

// appForbiddenString  Errore se è uguale alla stringa passata
// #############################################################################
@Directive({
    selector: '[appForbiddenString]',
    providers: [{provide: NG_VALIDATORS, useExisting: ForbiddenValidatorDirective, multi: true}]
})
export class ForbiddenValidatorDirective implements Validator {
    @Input() appForbiddenString: string;

    validate(control: AbstractControl): { [key: string]: any } {
        return this.appForbiddenString ? this.forbiddenStringValidator(new RegExp(this.appForbiddenString, 'i'))(control) : null;
    }

    forbiddenStringValidator(stringRe: RegExp): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const forbidden = stringRe.test(control.value);
            return forbidden ? {'appForbiddenString': true} : null;
        };
    }
}
// #############################################################################


// appCheckEqual Errore se è diverso alla stringa passata
// #############################################################################
@Directive({
    selector: '[appCheckEqual]',
    providers: [{provide: NG_VALIDATORS, useExisting: CheckEqualValidatorDirective, multi: true}]
})
export class CheckEqualValidatorDirective implements Validator, OnChanges {

    private _onChange: () => void;
    @Input() appCheckEqual: any;

    validate(control: AbstractControl): { [key: string]: any } {
        return this.appCheckEqual ? this.checkEqualValidator()(control) : null;
    }

    checkEqualValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            return this.appCheckEqual !== control.value ? {'appCheckEqual': true} : null;
        };
    }

    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }

    ngOnChanges(changes: SimpleChanges): void {
        if ('appCheckEqual' in changes) {
            if (this._onChange) {
                this._onChange();
            }
        }
    }

}
// #############################################################################



// NumberToLongDirective  Errore se numero è maggiore di 100000000
// #############################################################################
@Directive({
    selector: '[appNumberTooLong]',
    providers: [{provide: NG_VALIDATORS, useExisting: NumberToLongDirective, multi: true}]
})
export class NumberToLongDirective implements Validator {

    @Input() appNumberTooLong: any;

    validate(control: AbstractControl): { [key: string]: any } {
         return this.checkNumberTooLong()(control);
    }

    checkNumberTooLong(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (control.value > this.appNumberTooLong) {
                return {'appNumberTooLong': true};
            } else {
                return null;
            }
        };
    }
}
// #############################################################################
