import {MatPaginatorIntl} from '@angular/material';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';


@Injectable()
export class MatPaginatorIntlIta extends MatPaginatorIntl {
	

	
	 constructor(private translate: TranslateService) {
		super();
		

	this.translate.onLangChange.subscribe((e: Event) => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }
	
	
	getAndInitTranslations() {
    this.translate.get(['ITEMS PER PAGE', 'NEXT PAGE', 'PREVIOUS PAGE', 'OF LABEL']).subscribe(translation => {
      this.itemsPerPageLabel = translation['ITEMS PER PAGE'];
      this.nextPageLabel = translation['NEXT PAGE'];
      this.previousPageLabel = translation['PREVIOUS PAGE'];
      this.changes.next();
    });
  }


    getRangeLabel = function (page, pageSize, length) {
        if (length === 0 || pageSize === 0) {
            return '0 od ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' di ' + length;
	};
	
/* 	getItaPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();
  
  paginatorIntl.itemsPerPageLabel = 'righe per pagina';
  paginatorIntl.nextPageLabel = 'prossima pagina';
  paginatorIntl.previousPageLabel = 'pagina precedente';
//   paginatorIntl.getRangeLabel = getRangeLabel;
  
  return paginatorIntl;
} */

}