import * as $ from 'jquery';
import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserLoginService} from './shared/services';
import {LoadingBarService} from '@ngx-loading-bar/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  constructor(
      translate: TranslateService, private userLoginService: UserLoginService,
      public loader: LoadingBarService
  )  {
    translate.setDefaultLang('en');
    translate.use('en');
    this.userLoginService.setTheme(this.userLoginService.getTheme() || this.userLoginService.LIGHT_THEME);
  }
}
