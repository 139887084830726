import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AccountGroup, Pagination} from '../../../models';
import {BaseCrudHttpService} from '../base-crud-http.service';
import { Observable } from 'rxjs';
import { Sort } from '../../../models/sort';
import {ImportExecutionLog} from '../../../models/Importer/ImportExecutionLog'

@Injectable()
export class ImportLogHttpService extends BaseCrudHttpService<ImportExecutionLog> {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/importlog/');
    }

    private URLFindAllNew = this.URLMicroService;
    private URLFindByIdNew = this.URLMicroService;

    findAllNew( page: number, size: number, filters?: Map<string, string> ,sortList?: Array<Sort>): Observable<Pagination<ImportExecutionLog>> {     
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('size', size.toString());

        if(filters){
            filters.forEach((filterValue, filterKey) => {
                params = params.append(filterKey,filterValue);
            });
        }

        if (sortList) {
            sortList.forEach(sort => {
                params = params.append('order', sort.paramName + '_' + sort.direction.toUpperCase());
            })
        }
        return this.httpClient.get<Pagination<ImportExecutionLog>>(this.URLFindAllNew, {params: params});
    }
    
    public findById(id: string): Observable<ImportExecutionLog> {
        return this.httpClient.get<ImportExecutionLog>(`${this.URLFindByIdNew}/${id}`);
    }

}
