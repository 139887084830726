import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-spinner-list',
    templateUrl: './spinner-list.component.html'
})
export class SpinnerListComponent {

    @Input() isSpinnerActive = false;
    @Input() listLength = 0;

    constructor() {
    }

}
