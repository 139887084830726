import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GroupRole} from '../../models';
import {BaseCrudHttpService} from './base-crud-http.service';

@Injectable()
export class GroupRoleHttpService extends BaseCrudHttpService<GroupRole> {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/configurations/accountRole/');
    }

}
