import {Injectable} from '@angular/core';
import {EnumOperator} from '../models/Enums/EnumOperator';

@Injectable()
export class SloValidation {

    constructor() {}

    static isNonInTargetSLO(status:number, target:number , operator:EnumOperator):boolean{
        if (operator == null){
            operator = EnumOperator.MOREQUAL;
        }
        switch (operator){
            case "MORE":
                if ( status <= target ) 
                    return true;
                break;
            case "LESS":
                if ( status >= target ) 
                    return true;
                break;
            case "EQUAL":
                if ( status != target ) 
                    return true;
                break;
            case "MOREQUAL":
                if ( status < target ) 
                    return true;
                break;
            case "LESSEQUAL":
                if ( status > target )
                    return true;
                break;
            default:
                break;
            }
        return false;      
    }
}