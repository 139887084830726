import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {Account, Paginator} from '../../models';
import {MAT_DIALOG_DATA, MatDialogRef, MatPaginator} from '@angular/material';
import {AccountHttpService} from '../../services';
import {environment} from '../../../../environments/environment';
import { Sort } from '../../models/sort';

@Component({
  selector: 'app-actor-smart-list',
  templateUrl: './actor-smart-list.component.html',
  styleUrls: ['./actor-smart-list.component.scss']
})
export class ActorSmartListComponent implements OnInit {

  @ViewChild(MatPaginator) matPaginator: MatPaginator;

  @Input() actors: Account[] = [];
  @Input() needPagination: boolean;
  @Input() title = '';
  @Input() selectableList: boolean;
  @Input() usersGroup: string;

  paginator: Paginator = new Paginator();
  public env = environment;

  constructor(
      private businessAccountHttpService: AccountHttpService,
      public dialogRef: MatDialogRef<ActorSmartListComponent>,
      @Inject(MAT_DIALOG_DATA) public dialogData
  ) {
    this.title = this.title || dialogData['title'] || '';
    this.usersGroup = this.usersGroup || dialogData['usersGroup'] || '';
    this.needPagination = typeof this.needPagination === 'undefined' ? dialogData['needPagination'] : this.needPagination;
    this.selectableList = typeof this.selectableList === 'undefined' ? dialogData['selectableList'] : this.selectableList;
    this.actors = this.actors.length ? this.actors : dialogData['actors'] || [];
  }

  ngOnInit() {
    if (this.actors.length) {
        this.paginator.length = this.actors.length;
    } else {
      this.loadActors();
    }
  }

  loadActors() {
      let groupFilter = 'accountGroups.code=' + this.usersGroup;

      this.businessAccountHttpService.findAllWithPagesAndFilters(groupFilter, this.paginator.page, this.paginator.pageSize, [new Sort('name', 'DESC')]).subscribe((response) => {
          this.paginator.length = response.totalElements;
          this.actors = response.content;
      });
  }

  onPageChanged() {
      this.paginator.pageSize = this.matPaginator.pageSize;
      this.paginator.page = this.matPaginator.pageIndex;
      this.loadActors();
  }

  selectAndCloseActor(actor: Account) {
    if (this.selectableList) {
      this.dialogRef.close(actor);
    }
  }

}
