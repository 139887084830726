import {Account} from './account';
import {Sla} from './sla';
import {CustomerSupplier} from './customerSupplier';
import {EnumSectionType} from './Enums/EnumSectionType';
import {EnumTargetSLOType} from './Enums/EnumTargetSLOType';
import {EnumOperator} from './Enums/EnumOperator'

export class Slo {
    id: number;
    actualSLO: string;
    admin: Account;
    associateSLO: string;
    app: string;
    area: string;
    createdAt: Date;
    customerSupplier: CustomerSupplier;
    cutoffDate: Date;
    description: string;
    group: Group;
    endDate: Date;
    monitorDate: Date;
    monitoringSLO: string;
    nat: string;
    targetSLO: number;
    targetSLOString: string;
    targetSLOType: EnumTargetSLOType;
    targetSLODescription: string;
    sloCode: string;
    slaCode: string;
    sla: Sla;
    startDate: Date;
    functionArea: string;
    service: string;
    subcategory: string;
    sectionType: EnumSectionType;
    updatedAt: Date;
    penaltiesServiceCredits: string;
	sectionSlo: SectionSLO;
    inTarget: string;
    critical: string;
    penalties: string;
    reportName: string;
    reportType: string;
    operator: EnumOperator;
    version: number;
	
	motivation = 'pippo';
	SLOStatus: number = 999;
	SLOStatusText: string;
	//SLOStatusField: string; //TODO Da eliminare probabilmente
	sectionSloId: number;
	sloStatusMonth: string;
	motivationEditable: Boolean;
    statusSloEditable: Boolean;
    statusSloUpdatedMonth: number;
}

export class Group {
    id: number;
    nameGroup: string;
}

export class SectionSLO {
    createdAt: string;
    slo: Slo = null;
    id: number;
    motivation = '';
    nat: string;
    slostatus: number = null;
    slostatusText: string;
    updatedAt: string;
	sectionType: EnumSectionType;
    prevSLOStatus: number;
    prevMotivation: string;
    sectionSloSelectedMonth: string;
    sectionSloSelectedYear: string;
    prevSLOStatusText: String;
    hasValue: string;
	sectionSloLastUpdateTimeByHistory: Date;
    sectionSLOHistoryUpdatedMonth: Array<number> = [];
}

