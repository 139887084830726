import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {NotificationsHttpService} from './http/notifications-http.service';
import {AccountRole} from '../models';

@Injectable()
export class UserLoginService {

    private renderer: Renderer2;

    notificationsNumberByAccount = 0;
    notificationsNumberByGroup = 0;
    permissions = [];
    public readonly LIGHT_THEME = 'light-theme';
    public readonly DARK_THEME = 'dark-theme';

    constructor(private notificationsHttpService: NotificationsHttpService, rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public logout() {
        this.deleteCookie('isLoggedin');
        this.deleteCookie('Authorization');
        localStorage.removeItem('USER_OBJECT');
        localStorage.removeItem('KEY_VALUE_LISTS');
        localStorage.removeItem('userRole');
        this.permissions = [];
    }

    setCookie(key, value, ricordami) {
        let expires = '';
        if (ricordami) {
            let exdays = 30;
            let d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            expires = 'expires=' + d.toUTCString();
        }
        document.cookie = key + '=' + value + ';' + expires + ';path=/';
    }

    getCookie(key) {
        let name = key + '=';
        let value = '';
        let decodedCookie = decodeURIComponent(document.cookie);

        decodedCookie.split(';').some(c => {
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                value = c.substring(name.length, c.length);
                return true;
            }
        });
        return value;
    }

    getPermissions() {
        if (!this.permissions.length) {
            this.loadEndPointsPermissions();
            this.loadMenuPermissions();
        }
        return this.permissions;
    }

    private loadEndPointsPermissions() {
        for (let role of this.getRole()) {
            this.permissions.push(role.role);
        }
    }

    private loadMenuPermissions() {
        let resourceIdArray = [];
        this.permissions.forEach((permission: string) => {
            resourceIdArray[permission.split('@')[0]] = true;
        });

        Object.keys(resourceIdArray).forEach(key => {
            this.permissions.push(key + '@menu');
        });
    }

    public getRole() {
        return JSON.parse(localStorage.getItem('userRole')) as AccountRole[];
    }

    public setRole() {
        localStorage.setItem('userRole', JSON.stringify(this.getUserObjectField('BUSINESS_ACCOUNT')['roles']));
    }

    deleteCookie(name) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
    }

    public getIsLoggedin(): string {
        return this.getCookie('isLoggedin');
    }

    public setIsLoggedin(isLoggedIn, ricordami) {
        this.setCookie('isLoggedin', isLoggedIn, ricordami);
    }

    public getAuthorization(): string {
        return this.getCookie('Authorization');
    }

    public setAuthorization(authorization, ricordami) {
        this.setCookie('Authorization', authorization, ricordami);
    }

    getUserObject() {
        return JSON.parse(localStorage.getItem('USER_OBJECT'));
    }

    getUserObjectField(field: string) {
        return JSON.parse(localStorage.getItem('USER_OBJECT'))[field];
    }

    setUserObject(fieldName: string, field: any) {
        let userObj = this.getUserObject() || {};
        userObj[fieldName] = field;
        localStorage.setItem('USER_OBJECT', JSON.stringify(userObj));
    }

    setNotificationsNumberByAccount() {
        this.notificationsHttpService.badgeNumberByAccount(this.getUserObjectField('BUSINESS_ACCOUNT')['id']).subscribe(count => {
            this.notificationsNumberByAccount = count;
        });
    }

    setNotificationsNumberByGroup() {
        this.notificationsHttpService.badgeNumberByAccountGroup(this.getUserObjectField('USER_GROUP_SELECTED')['nameGroup']).subscribe(count => {
            this.notificationsNumberByGroup = count;
        });
    }

    setTheme(theme: string) {
        this.renderer.removeClass(document.documentElement, this.getTheme());
        this.renderer.addClass(document.documentElement, theme);
        localStorage.setItem('THEME', theme);
    }

    getTheme() {
        return localStorage.getItem('THEME');
    }

}
