import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Sla} from '../../models';
import {BaseCrudHttpService} from './base-crud-http.service';

@Injectable()
export class SlaHttpService extends BaseCrudHttpService<Sla> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/sla/');
    }
}
