import {NgModule} from '@angular/core';
import {CommonModule, DatePipe, PercentPipe} from '@angular/common';
import {MaterialModule} from '../material-module';
import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';

import {AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective} from './accordion';
import {
    FormUploadComponent,
    SpinnerComponent,
    ValidationMessageModule,
    SpinnerListComponent,
    BaseMaterialTableComponent
} from './modules';

import {FilterOnNamePipe, DynamicPipe} from './pipes';
import {NgxPermissionsModule} from 'ngx-permissions';
import {FileSaverModule} from 'ngx-filesaver';
import {TextMaskModule} from 'angular2-text-mask';


@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        HttpClientModule,
        RouterModule,
        FormsModule,
        TextMaskModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        TranslateModule,
        ValidationMessageModule,
        NgxPermissionsModule
    ],
    declarations: [
        SpinnerComponent,
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective,
        FormUploadComponent,
        FilterOnNamePipe,
        DynamicPipe,
        SpinnerListComponent,
        BaseMaterialTableComponent
    ],
    exports: [
        CommonModule,
        SpinnerComponent,
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective,
        MaterialModule,
        AngularFontAwesomeModule,
        HttpClientModule,
        FormsModule,
        ValidationMessageModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        TranslateModule,
        FormUploadComponent,
        FilterOnNamePipe,
        DynamicPipe,
        NgxPermissionsModule,
        SpinnerListComponent,
        FileSaverModule,
        BaseMaterialTableComponent,
        TextMaskModule
    ],
    providers: [
        PercentPipe,
        DatePipe
    ]
})
export class SharedModule {
}
