export class BaseMatTableColumn {
    displayedColumns: string[] = [];
    displayedColumnsTranslate: string[] = [];
    displayedColumnsPipe: PipeOnColumn[] = [];
    isLink: IsLink[] = [];
	isModal: IsModal[] = [];
	isInputWithActionSloStatus: IsInputWithAction[] = [];
	isInputWithActionMotivation: IsInputWithAction[] = [];
	isDateStatusSlo: IsSelectAction[] = [];

    defaultActionButton: DefaultActionButtons = new DefaultActionButtons();
	customActionButtons: CustomActionButton[] = [];
}

export class IsLink {
    state: string;
}

export class IsModal {
    state: string;
}

export class IsInputWithAction {
    state: string;
}

export class IsSelectAction {
    state: string;
}

export class DefaultActionButtons {
    detail: DefaultActionButton = new DefaultActionButton();
    update: DefaultActionButton = new DefaultActionButton();
    delete: DefaultActionButton = new DefaultActionButton();
}

export class DefaultActionButton {
    state?: string;
    permissions?: string[] = [];
}

export class CustomActionButton extends DefaultActionButton {
    action?: string;
    actionTranslate: string;
    icon: string;
}

export class PipeOnColumn {
    pipe: any;
    arg?: any;
}


