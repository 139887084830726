import {AccountGroup} from './accountGroup';
import {AccountRole} from './accountRole';

export class Account {
    accountNonExpired: boolean;
    accountNonLocked: true;
    createdAt: number;
    credentialsNonExpired: boolean;
    email: string;
    enabled: boolean;
    firstLogin: boolean;
    id: string;
    nameGroup: string;
    ldapSID: string;
    name: string;
    roles: Array<AccountRole> = [];
    groupsList: Array<AccountGroup> = [];
    surname: string;
    token: string;
    emailEnabled: boolean;
    updatedAt: number;
    username: string;
    superAdmin: Account;

    getGroupIDString() : string{
        let groupsListIDs = []
        this.groupsList.forEach(group=>{
            groupsListIDs.push(group.id);
        });
        return groupsListIDs.toString();
    }
}
