import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseCrudHttpService} from './base-crud-http.service';
import {Group} from '../../models/slo';
import { Observable } from 'rxjs';

@Injectable()
export class GroupHttpService extends BaseCrudHttpService<Group> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/group/');
    }

    public update(object: Group): Observable<Group> {
        return this.httpClient.put<Group>(this.URLMicroService, object);
	}

    public insert(object: Group): Observable<Group> {
        return this.httpClient.post<Group>(this.URLMicroService, object);
    }
    
    public delete(id: string): Observable<Group> {
        return this.httpClient.delete<Group>(`${this.URLMicroService}/${id}`);
    } 
}
