import {Account} from './account';
import {CustomerSupplier} from './customerSupplier';

export class Sla {
    slaCode: string;
    admin: Account;
    area: string;
    functionArea: string;
    createdAt: number;
    customer: CustomerSupplier;
    supplier: CustomerSupplier;
    endDate: string;
    group: Account;
    id: number;
    nat: string;
    startDate: string;
    updatedAt: number;
    category: string;
    note: string;
}

