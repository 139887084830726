export class Notification {

    id: string;
    read = false;
    type: string;
    description: string;
    date: string;
    reminderDate: string;
    businessProcessId: string;
    businessProcessName: string;
    businessProcessTaskDescription: string;
    businessProcessTaskId: string;
    fromBusinessAccountId: string;
    fromBusinessAccountName: string;
    fromfromBusinessAccountPhotoUrl: string;
    toBusinessAccountGroupCode: string;
    toBusinessAccountId: string;
    toBusinessAccountName: string;
    businessDocumentCode: string;
    businessDocumentData: BusinessDocumentNotificationData[] = [new BusinessDocumentNotificationData()];
}

export class BusinessDocumentNotificationData {
    id: string;
    name: string;
}

export enum NOTIFICATION_TYPE {
    UPLOAD_DOCUMENT_ON_GROUP = 'UPLOAD_DOCUMENT_ON_GROUP',
    UPDATE_DOCUMENT_ON_TASK = 'UPDATE_DOCUMENT_ON_TASK',
    START_PROCESS = 'START_PROCESS',
    ASSIGNED_TASK = 'ASSIGNED_TASK'
}

export class NotificationTypeIcons {
    UPLOAD_DOCUMENT_ON_GROUP = 'insert_drive_file';
    UPDATE_DOCUMENT_ON_TASK = 'insert_drive_file';
    START_PROCESS = 'play_arrow';
    ASSIGNED_TASK = 'assignment_turned_in';

    constructor() {
    }

}
