import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpEventType} from '@angular/common/http';
import 'rxjs/add/operator/catch';

import {Observable} from 'rxjs/Observable';
import {CustomSnackBarComponent, UserLoginService} from '../index';
import 'rxjs/add/operator/finally';
import 'rxjs/add/observable/throw';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

    constructor(
        private userLoginService: UserLoginService,
        private router: Router,
        public snackBar: MatSnackBar,
        private translate: TranslateService,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<HttpEventType.Response>> {
        let userId = this.userLoginService.getUserObject() ? this.userLoginService.getUserObjectField('BUSINESS_ACCOUNT')['token'] : '';
        let headers = {};

        if (localStorage.getItem('isFileToStorage')) {
            headers = {
                Accept: 'application/json',
                Authorization: this.userLoginService.getAuthorization(),
                'X-User-Id': userId
            };
        } else if (localStorage.getItem('isBlob')) {
            headers = {
                responseType: 'blob',
                Authorization: this.userLoginService.getAuthorization(),
                'X-User-Id': userId
            };
        } else if (localStorage.getItem('isPdf')) {
            headers = {
                observe: 'response',
                responseType: 'blob',
                Authorization: this.userLoginService.getAuthorization(),
                'X-User-Id': userId,
                Accept : 'application/pdf',             
            };
        } else if (this.userLoginService.getAuthorization()) {
            headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: this.userLoginService.getAuthorization(),
                'X-User-Id': userId
            };
        }
        localStorage.removeItem('isFileToStorage');
        localStorage.removeItem('isBlob');
        localStorage.removeItem('isPdf');
        return this.handleRequest(request, next, headers);
    }

    handleRequest(request: HttpRequest<any>, next: HttpHandler, headers) {
        request = request.clone({setHeaders: headers});
        return next.handle(request).catch(response => {

            let apiError = response.error;
            let messageToPrint = this.localizeMessage(apiError);

            if (response.status === 401) {
                this.logOut();
            }

            this.snackBar.openFromComponent(CustomSnackBarComponent, {
                    data: messageToPrint,
                    panelClass: 'error-snack-bar'
            });
            
            return Observable.throwError(response);
        });
    }

    logOut() {
        this.userLoginService.logout();
        this.router.navigate(['/login']);
    }


    localizeMessage(apiError:any):string{
        let errorTranslated;
        let messageToPrint;
        let errorKey = 'errorCodes.'+ apiError.code;

        //Prova a tradurre il codice di errore nel messaggio, nel caso non ci sia restituisce la errorKey
        this.translate.get(errorKey,apiError.argsErrorMessage).subscribe((translation: string) => {
            errorTranslated = translation;
        });

        //Se il valore tradotto è uguale alla errorKey e quindi non esiste una traduzione scrive nel messaggio il messaggio di backend (sempre se non è vuoto)
        if(errorKey == errorTranslated){
            if(apiError.errorDescription && apiError.errorDescription.trim() != ""){
                messageToPrint = 'Backend error: ' + apiError.errorDescription;
            } else {
                messageToPrint = 'errorCodes.0000';
            }
        } else {
            messageToPrint = errorTranslated;
        }
        return messageToPrint;
    }

}
