import {Component, ContentChildren, Input, OnDestroy, OnInit, QueryList} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs/Subscription';



@Component({
    selector: 'app-validation-message',
    template: '<ng-content *ngIf="show"></ng-content>'
})
export class ValidationMessageComponent {
    @Input() name: string;
    show = false;

    showsErrorIncludedIn(errors) {
        return errors.some(error => {
            if (error === this.name) {
                this.show = true;
                return;
            }
        });
    }
}


@Component({
    selector: 'app-validation-messages',
    template: '<ng-content *ngIf="for.touched || for.dirty"></ng-content>'
})
export class ValidationMessagesComponent implements OnInit, OnDestroy {

    @Input() for: FormControl;
    @ContentChildren(ValidationMessageComponent) messageComponents: QueryList<ValidationMessageComponent>;

    private statusChangesSubscription: Subscription;

    ngOnInit() {
        this.statusChangesSubscription = this.for.statusChanges.subscribe(x => {
            this.messageComponents.forEach(messageComponent => messageComponent.show = false);
            this.checkErrors();
        });
    }


    checkErrors() {
        if (this.for.invalid) {
            this.messageComponents.find(messageComponent => {
                return messageComponent.showsErrorIncludedIn(Object.keys(this.for.errors));
            });
        }
    }

    ngOnDestroy() {
        return this.statusChangesSubscription && this.statusChangesSubscription.unsubscribe();
    }
}
