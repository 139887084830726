import {Account} from './account';
import {Invitation} from './invitation';

export class BusinessPostit {
    businessAccount: Account;
    createdDate: string;
    id: string;
    invitations: Array<Invitation> = [];
    postitDate: string;
    text: string;
    type: string; // valori ammessi: "PostIt", "Call", "Meeting"
}
