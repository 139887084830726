import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {AppRoutes} from './app.routing';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MAT_DATE_LOCALE, MAT_SNACK_BAR_DEFAULT_OPTIONS, MatPaginatorIntl} from '@angular/material';
import localeIt from '@angular/common/locales/it';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import {
    AccountGroupHttpService,
    AuthGuard,
    AuthHttpService,
    AccountHttpService,
    BusinessPostitHttpService,
    CheckNotificationsGuard,
    CountryHttpService,
    CustomSnackBarComponent,
    EndpointHttpService,
    GroupRoleHttpService,
    IndustryHttpService,
    KeyValueHttpService,
    KeyValueService,
    LoginGuard,
    MatPaginatorIntlIta,
    MessageDialogComponent,
    NotificationsHttpService,
    PermissionsGuard,
    ProvinceHttpService,
    ResourceHttpService,
    RoleEndpointHttpService,
    SharedModule,
    UploadFileService,
    UserLoginService,
    CustomerSupplierHttpService,
    SlaHttpService,
    SloHttpService,
    ReportHttpService,
    UserHttpService,
    GroupHttpService,
    SectionSloHttpService
} from './shared';
import { OfficeSloMappingHttpService } from './shared/services/http/slo-office-mapping-http.service';
import {HeaderInterceptor} from './shared/interceptor/header.interceptor';
import {NgxPermissionsModule} from 'ngx-permissions';
import {SideMenuNavService} from './shared/services/side-menu-nav.service';
import { ImportLogHttpService } from './shared/services/http/Importer/log-http.service';
import { CalcoloSloHttpService } from './shared/services/http/calcolo-slo-http-service';
import { ImportRecordLogHttpService } from './shared/services/http/Importer/import-record-log-http-service';
import { ImportRecordFieldLogHttpService } from './shared/services/http/Importer/import-record-field-log-http-service';
import { WorkingDaysHttpService } from './shared/services/http/working-days-http.service';

registerLocaleData(localeIt);

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        CustomSnackBarComponent,
        MessageDialogComponent
    ],
    imports: [
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
        }),
        RouterModule.forRoot(AppRoutes, {
            useHash: true,
            onSameUrlNavigation: 'reload'
        }),
        NgxPermissionsModule.forRoot()
    ],
    entryComponents: [
        CustomSnackBarComponent,
        MessageDialogComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor,
            multi: true
        },
        AuthGuard,
        CheckNotificationsGuard,
        LoginGuard,
        PermissionsGuard,
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {provide: MatPaginatorIntl, useClass: MatPaginatorIntlIta },
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 3000}},
        {provide: LOCALE_ID, useValue: 'en'},
		{provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
		
		
        UserLoginService,
        AccountGroupHttpService,
        GroupRoleHttpService,
        AuthHttpService,
        KeyValueHttpService,
        KeyValueService,
        AccountHttpService,
        BusinessPostitHttpService,
        CustomerSupplierHttpService,
        SlaHttpService,
        SectionSloHttpService,
        SloHttpService,
        UserHttpService,
        GroupHttpService,
        CountryHttpService,
        IndustryHttpService,
        NotificationsHttpService,
        ProvinceHttpService,
        UploadFileService,
        RoleEndpointHttpService,
        EndpointHttpService,
        ResourceHttpService,
        SideMenuNavService,
        ReportHttpService,
        OfficeSloMappingHttpService,
        ImportLogHttpService,
        ImportRecordLogHttpService,
        ImportRecordFieldLogHttpService,
        CalcoloSloHttpService,
        WorkingDaysHttpService
    ],
	bootstrap: [AppComponent]
	
})
export class AppModule {
}
