import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BaseCrudHttpService} from './base-crud-http.service';
import {SectionSLO, Slo} from '../../models/slo';
import {Observable} from 'rxjs';

@Injectable()
export class SectionSloHttpService extends BaseCrudHttpService<SectionSLO> {
	private _findBySLOId = this.URLMicroService + 'findBySLOId';
	private _findSectionSLOBySlo = this.URLMicroService + 'findSectionSLOBySlo';
	private _findById = this.URLMicroService + 'findById';

    constructor(httpClient: HttpClient) {
        super(httpClient, '/sectionSLO/');
    }

    public findBySLOId(idSlo: string): Observable<SectionSLO[]> {
        return this.httpClient.get<SectionSLO[]>(this._findBySLOId + '/' + idSlo );

	}

	 public findBySectionId(idSectionSlo: string): Observable<SectionSLO> {
        return this.httpClient.get<SectionSLO>(this._findById + '/' + idSectionSlo );

	}

	public findSectionSLOBySlo(sloList: Slo): Observable<SectionSLO[]> {
        return this.httpClient.get<SectionSLO[]>(this._findSectionSLOBySlo + '/' + sloList );

	}
	
	
}
