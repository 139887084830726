import {Account} from './account';
import { Report } from './Report';

export class CustomerSupplier {
    address: string;
    city: string;
    companyName: string;
    country: string;
    createdAt: number;
    id: number;
    nation: string;
    admin: Account;
    postalCode: string;
    updatedAt: number;
    customerRole: boolean;
    supplierRole: boolean;
    reportList: Array<Report>
}
