import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Endpoint} from '../../models';
import {BaseCrudHttpService} from './base-crud-http.service';

@Injectable()
export class EndpointHttpService extends BaseCrudHttpService<Endpoint> {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/endpoint/endpointService/');
    }

}
