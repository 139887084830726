import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {UserLoginService} from '../services';

@Injectable()
export class CheckNotificationsGuard implements CanActivate, CanActivateChild {


  constructor(private router: Router, private userLoginService: UserLoginService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return this.checkNotifications();
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkNotifications();
    }

    checkNotifications() {
      this.userLoginService.setNotificationsNumberByAccount();
      this.userLoginService.setNotificationsNumberByGroup();
      return true;
    }
}
