import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BusinessPostit, CustomerSupplier} from '../../models';
import {BaseCrudHttpService} from './base-crud-http.service';
import {Observable} from 'rxjs';

@Injectable()
export class CustomerSupplierHttpService extends BaseCrudHttpService<CustomerSupplier> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/customerSupplier/');
    }

    public findAllNew(): Observable<Array<CustomerSupplier>> {
        return this.httpClient.get<any>(this.baseUrl + "/customerSupplier/");
    }
}
